import React, { createContext, useContext, useState } from 'react';

export const ThemeContext = createContext();

export default function ThemeContextProvider({ children }) {
    const [primaryColor, setPrimaryColor] = useState('#000000');
    const [secondaryColor, setSecondaryColor] = useState('#000000');
    const [secondarySelectedColor, setSecondarySelectedColor] = useState('#000000');
    const [customStyles, setCustomStyles] = useState('');

    const importTheme = (theme) => {
        setPrimaryColor(theme.primary);
        setSecondaryColor(theme.secondary);
        setSecondarySelectedColor(theme.secondarySelected);
        setCustomStyles(theme.customStyles);
    }

    return (
        <ThemeContext.Provider value={{ 
            importTheme,
            primaryColor,
            secondaryColor,
            secondarySelectedColor,
            customStyles
        }}>
            {children}
        </ThemeContext.Provider>
    );
}

export function useThemeContext() {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
}