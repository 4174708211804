import { nookal } from '../services/nookal.js';

export class Allsports {

    constructor() {
        this.methods = nookal;
        this.useCategory = true;
    }

    theme() {
        const colors = { 
            primary: '#1c1d28',
            secondary: '#e30032',
            secondarySelected: '#4b5054'
        }

        const customStyles = '';

        return {
            ...colors,
            customStyles
        };
    }

}