/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import Sidebar from 'src/components/Sidebar.js';
import BookingForm from 'src/components/BookingForm';
import { useGlobalContext } from 'src/context/GlobalContext';
import Navigation from 'src/components/Navigation';

const BookingView = () => {

    const {
        bookingDetails,
        setBookingDetails,
        handleWindowChange,
        isMobile,
        branch
    } = useGlobalContext();

    const [formCompleted, setFormCompleted] = useState(false);
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        validateFields(bookingDetails);
    }, []);

    const validateFields = (details) => {
        var isValid = true;
        let currErrors = {};
        branch.formFields.forEach((field) => {
            if(details[field.name] === '' && field.required) {
                currErrors[field.name] = "This field is required";
                isValid = false;
            } else if((field.name === 'email' && !details[field.name].match(/^[\w+-.]+@([\w-]+\.)+[\w-]{2,8}$/))) {
                currErrors[field.name] = "Please provide a valid Email";
                isValid = false;
            } else if((field.name === 'DOB') && !details[field.name]._isValid) {
                currErrors[field.name] = "Please provide a valid Date of Birth";
                isValid = false;
            } else if(field.name === 'phone' && !details[field.name].replace(/ /g, "").match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/)) {
                currErrors[field.name] = "Invalid Phone Number";
                isValid = false;
            } else {
                currErrors[field.name] = '';
            }
        });
        setErrors(currErrors);
        setFormCompleted(isValid);
    }

    const formChangeHandler = (e) => {
        let details = {};
        if(e) {
            if(!e.target) details = {...bookingDetails, DOB: e};
            else if(!e.target.id && e.target.name) details = {...bookingDetails, [e.target.name]: e.target.value};
            else details = {...bookingDetails, [e.target.id]: e.target.value};
        }
        validateFields(details);
        setBookingDetails(details);
    }

    const submitForm = (isNext) => {
        setShowErrors(true);
        if(formCompleted || !isNext) handleWindowChange(isNext);
    }

    return (
        <div className='view-wrapper'>
            <Grid container>
                <Grid item sm={8} xs={12} sx={{ padding: 3, borderRight: isMobile ? "none" : "20px solid white" }}>
                    <div className='d-flex-center'>
                        <BookingForm 
                            formChangeHandler={formChangeHandler}
                            errors={errors}
                            showErrors={showErrors}
                        />
                    </div>
                    <Navigation 
                        disabled={!formCompleted} 
                        buttonValue={'Continue'}
                        onBack={() => submitForm(false)}
                        onContinue={() => submitForm(true)}
                    />
                </Grid>
                <Grid item sm={4} xs={12} sx={{ paddingLeft: 3 }}>
                    <Sidebar/>
                </Grid>
            </Grid>
        </div>
    )
};

export default BookingView;