import React from 'react';
import { FormControl, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { motion } from 'framer-motion';
import 'moment/locale/en-gb';
import { useThemeContext } from 'src/context/ThemeContext';
import { useGlobalContext } from 'src/context/GlobalContext';

const BookingForm = (props) => {

    const { primaryColor, secondaryColor, secondarySelectedColor } = useThemeContext();

    const { isMobile, branch, bookingDetails } = useGlobalContext();

    return (
        <FormControl sx={{ width: "100%",}}>
            <div className='page-title'>Please Complete Your Details (*required field)</div>
            <Grid
                container
                component="form"
                spacing={2}
                sx={{
                    '& .MuiTextField-root': { mt: 1, width: '100%' },
                    '& .MuiInputBase-input': { fontSize: '18px' },
                    '& .MuiInputLabel-root': { fontSize: '16px', color: "gray" },
                    '& .Mui-focused': { color: primaryColor + ' !important' }, 
                    "& .MuiFilledInput-underline:after": { borderBottom: `2px solid ${secondaryColor}` },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            borderColor: primaryColor,
                        },
                    },
                }}
                noValidate
                autoComplete="off"
            >
                {branch.formFields.map((field, index) => {
                    let fieldHtml;

                    if (field.type === 'radio') {
                        fieldHtml = (
                            <Grid 
                                item xs={12} 
                                md={field.size ? field.size : '12'} 
                                key={index} 
                                sx={{ 
                                    display: field.display ? field.display : 'block',
                                }}
                            >
                                <div 
                                    style={{
                                        marginTop: '10px',
                                        fontSize: '16px',
                                        color: '#272727',
                                    }}
                                >
                                    {field.label}
                                </div>
                                <RadioGroup
                                    name={field.name}
                                    id={field.name}
                                    key={field.name}
                                    row={true}
                                    onChange={props.formChangeHandler}
                                    className='d-flex-center'
                                    sx={{
                                        marginTop: '20px',   
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '14px',
                                        }, 
                                        '& .MuiRadio-root': {
                                            display: 'none',
                                        },  
                                        userSelect: 'none',
                                    }}
                                    value={bookingDetails[field.name] ? bookingDetails[field.name] : field.options[0]}
                                >
                                    {field.options.map((option, index) => {
                                        return (
                                            <motion.div whileTap={{ scale: 1.2, opacity: '100%' }} key={option}>
                                                <FormControlLabel
                                                    key={index}
                                                    sx={{
                                                        background: option === bookingDetails[field.name] ? secondaryColor : secondarySelectedColor,
                                                        ":hover": {
                                                            opacity: option === bookingDetails[field.name] ? '100% ' : '80%',
                                                        },
                                                        padding: isMobile < 480 ? "10px 22px" : option === bookingDetails[field.name] ? '17px 20px' : '15px 20px',
                                                        margin: option === bookingDetails[field.name] ? '0px' : '2px 0 0 0',
                                                        color: 'white',
                                                        borderRadius: index === 0 ? '5px 0 0 5px' : index === (field.options.length - 1) ? '0 5px 5px 0' : '0',
                                                    }}
                                                    name={field.name}
                                                    value={option}
                                                    control={<Radio />}
                                                    label={option} />
                                            </motion.div>
                                        )
                                    })}
                                </RadioGroup>
                            </Grid>
                        )  
                    } else if (field.type === 'date') {
                        fieldHtml = (
                            <Grid item xs={12} md={field.size ? field.size : '12'} key={index}>
                                <div style={{marginTop: '10px'}}>
                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
                                        <DatePicker
                                            label={field.label}
                                            value={bookingDetails[field.name] ? bookingDetails[field.name] : null}
                                            onChange={props.formChangeHandler}
                                            name={field.name}
                                            id={field.name}
                                            key={field.name}
                                            required={field.required}
                                            helperText={props.showErrors ? props.errors[field.name] : ''}
                                            error={(props.errors[field.name] && props.showErrors) ? true : false}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div> 
                            </Grid>
                        )    
                    } else {
                        fieldHtml = (
                            <Grid item xs={12} md={field.size ? field.size : 12} sx={{marginTop: '10px'}} key={index}>
                                <TextField
                                    name={field.name}
                                    id={field.name}
                                    key={field.name}
                                    helperText={props.showErrors ? props.errors[field.name] : ''}
                                    error={(props.errors[field.name] && props.showErrors) ? true : false}
                                    type={field.type ? field.type : 'text'}
                                    value={bookingDetails[field.name] ? bookingDetails[field.name] : ''}
                                    label={field.label}
                                    variant="filled"
                                    onChange={props.formChangeHandler}
                                    multiline={field.type === 'textarea' ? true : false}
                                    rows={field.type === 'textarea' ? 4 : 1}
                                    required={field.required} />
                            </Grid>
                        )
                    }

                    return(fieldHtml)
                })}
            </Grid>
        </FormControl>
    )
}


export default BookingForm;