import React, { useEffect, useState } from 'react';

// Styles
import 'src/css/styles.css';

// Components
import Steps from 'src/components/Steps';

// Views
import TypeSelectView from 'src/Views/TypeSelectView';
import BookingView from 'src/Views/BookingView';
import ConfirmationView from 'src/Views/ConfirmationView';
import ThankyouView from './Views/ThankyouView';

// Models
import { Branch } from 'src/data/Branch';

// Context
import { useGlobalContext } from './context/GlobalContext';
import { useThemeContext } from './context/ThemeContext';

const BookingPortal = () => {

  const [initialized, setInitialized] = useState(false);
  const [selectedStep, setSelectedStep] = useState(0);

  const { 
    windowNumber, 
    setWindowNumber,
    setPractitioners,
    setAppointmentTypes,
    setBranch,
    branch,
    bookingDetails,
    setBookingDetails,
    setBookingSubmitting,
    setBookingSuccess,
    isMobile
  } = useGlobalContext();

  const { importTheme, customStyles } = useThemeContext();

  // On app load
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!initialized) {
      init();
      setInitialized(true);
    }
    setSelectedStep(windowNumber < 2 ? windowNumber : 2);
  });

  const init = async () => {
    // Initialize the branch
    const branch = new Branch();
    setBranch(branch);

    // Import the theme
    importTheme(branch.theme);

    // Test connection to the server
    const connectionData = await branch.init();
    if(connectionData === undefined || connectionData === false) {
      setWindowNumber(-1);
      return;
    }

    // Get the appointment types and practitioners
    setAppointmentTypes(await branch.client.methods.getAppointmentTypes());
    setPractitioners(await branch.client.methods.getPractioners(branch.identifier));

    // Get form fields
    const fields = {};
    branch.formFields.forEach(field => {
      fields[field.name] = field.defaultValue;
    });

    // Set default booking details
    setBookingDetails(fields);
  }

  // Get the current window
  const getWindow = () => {
    if (windowNumber === -1) return <div className='error-page'>Error: Could not connect to the server.</div>;
    else if (windowNumber === 0) return <TypeSelectView />;
    else if (windowNumber === 1) return <BookingView />;
    else if (windowNumber === 2) return <ConfirmationView submitBooking={submitBooking}/>;
    else if (windowNumber === 3) return <ThankyouView />;
  }

  // On submit
  const submitBooking = async() => {
    setBookingSubmitting(true);
    if (bookingDetails) {
      await postAppointment().then((success) => {
        setBookingSubmitting(false);
        setBookingSuccess(success);
      });
    }
  }

  const postAppointment = async () => {
    let success = false;
    try {
      if(process.env.NODE_ENV !== 'development') {
        // Post the appointment to the respective server
        success = await branch.client.methods.postAppointment({
          startdate: bookingDetails.dateTime.format('YYYY-MM-DD'),
          starttime: bookingDetails.dateTime.format('HH:mm'),
          branchIdentifier: branch.identifier,
          practitionerID: bookingDetails.doctorID,
          appointmentType: bookingDetails.appointmentType,
          bookingDetails: bookingDetails,
          notes: bookingDetails.notes
        });
      } else {
        success = true;
      }
      
      // Send the confirmation message via email
      let message = '?name=' + bookingDetails.firstName
        + '&client_email=' + (process.env.NODE_ENV === 'development' ? 'jeremy@techthisout.com.au' : bookingDetails.email)
        + '&provider_email=' + (process.env.NODE_ENV === 'development' ? 'jeremy@techthisout.com.au' : branch.email)
        + '&phone=' + branch.phone
        + '&address=' + encodeURIComponent(branch.address)
        + '&postcode=' + branch.postcode
        + '&state=' + branch.state
        + '&suburb=' + branch.suburb
        + '&provider=' + bookingDetails.doctorName
        + '&practice_name=' + branch.name
        + '&time=' + bookingDetails.dateTime.format("hh:mm A")
        + '&date=' + bookingDetails.dateTime.format("Do MMMM, YYYY")
        + '&brand=' + branch.clientName
        + '&type=' + bookingDetails.appointmentTypeDesc;
  
      if (success) {
        if(branch.client.methods.sendConfirmMessage) branch.client.methods.sendConfirmMessage(message);
        if(branch.client.bookingCompleteEvents && process.env.NODE_ENV !== 'development') branch.client.bookingCompleteEvents();
      }
      return success;
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='app-wrapper' style={process.env.NODE_ENV === 'development' && !isMobile ? {width: '1000px', margin: '100px auto'} : {}}>
      <Steps step={selectedStep}/>
      {getWindow()}
      <style>
        {(process.env.NODE_ENV === 'development' && !isMobile) ? 'body {background-color: grey}' : ''}
        {customStyles}
      </style>
    </div>
  );

}

export default BookingPortal;