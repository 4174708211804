import React, { createContext, useContext, useState } from 'react';
import moment from 'moment';

export const GlobalContext = createContext();

export default function GlobalContextProvider({ children }) {
    const params = new URLSearchParams(window.location.search);
    const [branch, setBranch] = useState(null);
    const [practitioners, setPractitioners] = useState([]);
    const [appointmentTypes, setAppointmentTypes] = useState([]);
    const [windowNumber, setWindowNumber]  = useState(0);
    const [selectedType, setSelectedType] = useState({ID:""});
    const [isMobile, setIsMobile] = useState(document.getElementById('booking-wrapper') && document.getElementById('booking-wrapper').clientWidth < 480);
    const [bookingSubmitting, setBookingSubmitting] = useState(false);
    const [bookingSuccess, setBookingSuccess] = useState(false);
    const [currentPractitioners, setCurrentPractitioners] = useState([]);
    const [windowMovedBack, setWindowMovedBack] = useState(false);
    const [numAvailablePractitioners, setNumAvailablePractitioners] = useState(0);

    const [bookingDetails, setBookingDetails] = useState(params.get("test") ?
      {
        'email': 'test@test.com',
        'DOB':  moment('2022-04-17'),
        'firstName': 'Test',
        'lastName': 'Patient',
        'phone': '0419830851',
        'gender': "Female",
        'visited': 'No',
      } : {
        'email': '',
        'DOB': '',
        'firstName': '',
        'lastName': '',
        'phone': '',
        'gender': 'Male',
        'visited': 'No',
      }
    );

    const handleWindowChange = (isNext) => {
      
      if (isNext && windowNumber < 3) {
        setWindowMovedBack(false);
        setWindowNumber(windowNumber + 1);
      } else {
        setWindowMovedBack(true);
        setWindowNumber(windowNumber - 1);
      }
    }

    return (
        <GlobalContext.Provider value={{ 
            windowNumber, 
            setWindowNumber,
            selectedType,
            setSelectedType,
            practitioners,
            setPractitioners,
            appointmentTypes,
            setAppointmentTypes,
            branch,
            setBranch,
            bookingDetails,
            setBookingDetails,
            handleWindowChange,
            params,
            isMobile,
            setIsMobile,
            bookingSubmitting,
            setBookingSubmitting,
            bookingSuccess,
            setBookingSuccess,
            currentPractitioners,
            setCurrentPractitioners,
            windowMovedBack,
            setWindowMovedBack,
            numAvailablePractitioners,
            setNumAvailablePractitioners
        }}>
            {children}
        </GlobalContext.Provider>
    );
}

export function useGlobalContext() {
    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error('useGlobalContext must be used within a GlobalProvider');
    }
    return context;
}