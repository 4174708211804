import { optomate } from '../services/optomate.js';
 
export class OpticalCo {

    constructor() {
        this.methods = optomate;
        this.useCategory = false;
    }

    theme() {
        const colors = { 
            primary: '#1c5e72',
            secondary: '#5fc3b6',
            secondarySelected: '#113742'
        }

        const customStyles = '';

        return {
            ...colors,
            customStyles
        };
    }

}