import React from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { LinearProgress } from '@material-ui/core';
import { motion } from "framer-motion";
import Grid from '@mui/material/Grid';
import { useGlobalContext } from 'src/context/GlobalContext';
import moment from 'moment';
import { useThemeContext } from 'src/context/ThemeContext';

const ThankyouView = () => {

  const {
    bookingDetails,
    branch,
    bookingSuccess,
    bookingSubmitting,
  } = useGlobalContext();

  const { secondaryColor } = useThemeContext();

  const detailList = [
    {
      icon: <AssignmentIcon sx={{fontSize: '40px'}}/>,
      title: 'Location',
      detail: branch.name
    },
    {
      icon: <LocationOnIcon sx={{fontSize: '40px'}}/>,
      title: 'Address',
      detail: branch.address
    },
    {
      icon: <AccessTimeFilledIcon sx={{fontSize: '40px'}}/>,
      title: 'Date/Time',
      detail: bookingDetails.dateTime.format('Do MMMM h:mm a') + " - " + moment(bookingDetails.dateTime).add(bookingDetails.duration, 'minutes').format('h:mm a')
    },
    {
      icon: <AccountCircleIcon sx={{fontSize: '40px'}}/>,
      title: 'Practitioner',
      detail: bookingDetails.doctorName
    }
  ]

  return (
    bookingSuccess ?
    <motion.div animate={{ height: 'auto' }} initial={{ height: 0 }} transition={{ ease: "easeInOut", duration: 1 }} style={{ overflow: 'hidden' }}>
      <div className='view-wrapper' style={{ textAlign: 'center', paddingBottom: '20px' }}>
        <div className='page-title'>Your Booking is Confirmed</div>
        <div className='sub-title'>We look forward to seeing you for your appointment</div>
        <div className="booking-confirmation-wrapper">
          <Grid container>
            {detailList.map((item, index) => { 
              return (
                <Grid item key={index} xs={12} md={6} sx={{ textAlign: 'center', padding: '20px' }}>
                  <div className="detail-item-icon">{item.icon}</div>
                  <div className="detail-item-title">{item.title}</div>
                  <div className="detail-item-text">{item.detail}</div>
                </Grid>
              )
            })}
          </Grid>
        </div>
        <div className="detail-item-text" style={{ marginTop: '20px', marginBottom: '20px' }}>
          You will receive confirmation prior to your appointment and please contact us on <b>{branch.phone}</b> with any questions prior.
        </div>
      </div>
    </motion.div>
    :
    <div className='book-consultation-detail-content' style={{ textAlign: 'center', paddingBottom: '20px' }}>
      { bookingSubmitting ? 
        <div className='view-wrapper'>
          <LinearProgress sx={{ backgroundColor: 'lightgrey', '& .MuiLinearProgress-bar': { backgroundColor: secondaryColor } }}/>
        </div> 
        :
        <div className='view-wrapper'>
          <div className='title-large'>Something went wrong</div>
          <div className='title'>Please call {branch.phone} to make your booking</div>
        </div> 
      }
    </div>
  )

}

export default ThankyouView;