import React, { useState, useEffect } from 'react';
import { Grid, FormControl, Select, MenuItem } from '@mui/material';
import { useGlobalContext } from 'src/context/GlobalContext';
import { useAppointmentContext } from 'src/context/AppointmentContext';
import Skeleton from '@mui/material/Skeleton';
import { useThemeContext } from 'src/context/ThemeContext';

const AppointmentTypeSelect = () => {

    const { appointmentTypes, setSelectedType, selectedType, branch } = useGlobalContext();

    const { isNewConcern, isExistingPatient } = useAppointmentContext();

    const { secondaryColor } = useThemeContext();
    
    const [type, setType] = useState({ID: ""});
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [filteredTypes, setFilteredTypes] = useState([]);

    useEffect(() => {
        setType(selectedType);
        // Filter types based on new concern and existing patient
        filterTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedType, appointmentTypes, isNewConcern, isExistingPatient]);

    const filterTypes = () => {
        const types = [];
        appointmentTypes.forEach(type => {
            if (!isExistingPatient && type.description.includes('Initial')) types.push(type);
            else if (isExistingPatient) {
                if (isNewConcern) {
                    if (type.description.includes('Initial')) types.push(type);
                }  else {
                    if (!type.description.includes('Initial')) types.push(type);
                }
            }
        });
        // Get unique categories
        const categories = types.map(type => type.category);
        setCategories([...new Set(categories)]);
        setFilteredTypes(types);
    }

    const handleChange = (id) => {
        if(id.target) {
            id = id.target.value;
        }
        const selectedType = appointmentTypes.find(value => value.ID === id);
        if (selectedType) {
            setType(selectedType);
            // Defer the global state update slightly to avoid blocking the UI
            setTimeout(() => {
                setSelectedType(selectedType);
            }, 100);
        }
    }

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    }
    
    return (
        <div className='selected-type-select'>
            {filteredTypes.length === 0 ?
                <Skeleton variant="rounded" width={'100%'} height={40} style={{marginTop: '14px'}}/>
                : 
                // If the client uses categories
                branch.client.useCategory ?
                <div style={{width: '50%', margin: 'auto', textAlign:'center'}}>
                    <h2>Select a Service</h2>
                    <Grid container>
                        {categories.map((category, index) => (
                            <Grid key={index} item xs={12} md={6}>
                                <div 
                                    className={`type-select-item ${selectedCategory === category ? 'selected' : ''}`}
                                    style={{
                                        backgroundColor: selectedCategory === category ? secondaryColor : 'white'
                                    }} 
                                    onClick={() => handleCategoryChange(category)}
                                >
                                    <div className='type-select-item-text'>{category}</div>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid>
                        {selectedCategory && <h3>Select an Appointment Type</h3>}
                        {filteredTypes.filter(type => type.category === selectedCategory).map((appointmentType, index) => (
                            <Grid key={index} item xs={12} md={6}>
                                <div 
                                    className={`type-select-item ${type.ID === appointmentType.ID ? 'selected' : ''}`}
                                    style={{
                                        backgroundColor: type.ID === appointmentType.ID ? secondaryColor : 'white'
                                    }} 
                                    onClick={() => handleChange(appointmentType.ID)}
                                >
                                    <div className='type-select-item-text'>{appointmentType.description}</div>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div> :
                // If the client does not use categories use the select component
                <FormControl fullWidth variant="outlined">
                    <Select
                        displayEmpty
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            height: "40px",
                            width: "70%",
                            margin: "0 auto",
                            border: "1px solid #D9D9D9",
                            fontSize: "14px",
                            "&:focus": { borderColor: "#D9D9D9" }
                        }}
                        value={type.ID}     
                        onChange={handleChange}
                    >
                        <MenuItem value={""} style={{ color: "#B5B5B5" }}>Appointment type</MenuItem>
                        {filteredTypes && filteredTypes.map((appointmentType, index) => (
                            <MenuItem key={index} value={appointmentType.ID} sx={{ whiteSpace: "unset" }}>
                                {appointmentType.description}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }
        </div>
    );
}

export default AppointmentTypeSelect;

