import React from 'react';
import { Grid } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import moment from 'moment';
import { useAppointmentContext } from 'src/context/AppointmentContext';
import { useThemeContext } from 'src/context/ThemeContext';
import DateSelector from 'src/components/DateSelector';
import { getScrollbarWidth } from 'src/utils/functions';

const AppointmentListHeader = () => {

    const { startingDate, setStartingDate } = useAppointmentContext();
    const { primaryColor, secondaryColor } = useThemeContext();

    const handleDateChange = (isForward) => {
        if (isForward) {
          setStartingDate(moment(startingDate).add(5, 'days'));
        } else {
          if(moment().isSameOrBefore(moment(startingDate).subtract(5, 'days'), 'days')) setStartingDate(moment(startingDate).subtract(5, 'days'));
        }
    }

    const headers = [];
    for(let i = 0; i < 5; i++) {
        headers.push(
        <Grid key={i} item xs={2} className='date-header' sx={{backgroundColor: i % 2 === 0 ? '#f4f4f4' : '#fff'}}>
            <div>{moment(startingDate).add(i, 'days').format('ddd')}</div>
            <div>{moment(startingDate).add(i, 'days').format('D MMM')}</div>
        </Grid>
        )
    };

    return (
        <Grid container sx={{paddingRight: `${getScrollbarWidth()}px`,
            '& .MuiTextField-root': { width: '100%', backgroundColor: 'white', borderColor: 'red' },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: primaryColor,
                },
            },
            '& .MuiOutlinedInput-input': {
                fontSize: '14px',
                padding: '15px',
            },
            paddingTop: '10px'
        }}>
            <Grid item xs={6} sx={{pr: 2}}>
                <DateSelector/>
            </Grid>
            <Grid item xs={6}>
                <Grid container className='header-bar'>
                    <Grid item xs={1}>
                        { moment().isSameOrBefore(moment(startingDate).subtract(5, 'days'), 'days') && 
                        <div className='calendar-nav' onClick={() => handleDateChange(false)} style={{backgroundColor: secondaryColor}}>
                            <ArrowLeft />
                        </div> }
                    </Grid>
                    {headers}
                    <Grid item xs={1}>
                        <div className='calendar-nav' onClick={() => handleDateChange(true)} style={{backgroundColor: secondaryColor}}>
                            <ArrowRight />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid> 
    )
}

export default AppointmentListHeader;