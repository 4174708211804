import React from 'react';
import { Grid } from '@mui/material';
import { useThemeContext } from 'src/context/ThemeContext';

const Navigation = ({disabled, buttonValue, onBack, onContinue}) => {

  const { secondaryColor } = useThemeContext();

  return (
    <Grid container sx={{ mt: 5 }}>
      <Grid item xs={5} md={5}>
        <div 
        className='back-button' 
        style={{backgroundColor: secondaryColor}} 
        onClick={onBack}>
          <p className='button-text'>
            Back
          </p>
        </div>
      </Grid>
      <Grid item xs={7} md={7}>
        <div 
        className='continue-button' 
        style={{backgroundColor: disabled ? 'grey' : secondaryColor}} onClick={onContinue}>
          <p className='button-text'>
            {buttonValue}
          </p>
        </div>
      </Grid>
    </Grid>
  )
}

export default Navigation;