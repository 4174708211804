import { motion } from 'framer-motion';
import React from 'react';
import { useAppointmentContext } from 'src/context/AppointmentContext';
import { useThemeContext } from 'src/context/ThemeContext';

const PatientTypeToggle = () => {
    const { 
        isExistingPatient, 
        setIsExistingPatient,
        isNewConcern,
        setIsNewConcern
    } = useAppointmentContext();
    const { secondaryColor } = useThemeContext();

    const selectedStyle = {
        backgroundColor: secondaryColor,
        color: 'white',
        borderColor: 'white',
        transform: 'scale(1.1)'
    }

    return (
        <div>
            <div className='toggle-title'>Have you been to this clinic before?</div>
            <div className='d-flex-center'>
                <div 
                    className='patient-type' 
                    style={!isExistingPatient ? selectedStyle : {}}
                    onClick={() => setIsExistingPatient(false)}   
                >
                    I am a new patient
                </div>
                <div 
                    className='patient-type' 
                    style={isExistingPatient ? selectedStyle : {}}
                    onClick={() => setIsExistingPatient(true)}   
                >
                    I am an existing patient
                </div>
            </div>
            {isExistingPatient && 
                <motion.div 
                    animate={{ height: 'auto' }} 
                    initial={{ height: 0 }} 
                    transition={{ ease: "easeInOut", duration: 0.3 }} 
                    style={{padding: '20px 0', overflow: 'hidden'}}
                >
                    <div className='toggle-title' style={{marginTop: '20px'}}>Is this a new concern?</div>
                    <div className='d-flex-center'>
                        <div 
                            className='concern-option' 
                            style={isNewConcern ? selectedStyle : {}}
                            onClick={() => setIsNewConcern(true)}   
                        >
                            Yes
                        </div>
                        <div 
                            className='concern-option' 
                            style={!isNewConcern ? selectedStyle : {}}
                            onClick={() => setIsNewConcern(false)}   
                        >
                            No
                        </div>
                    </div>
                </motion.div>   
            }
        </div>
    )
}

export default PatientTypeToggle;