import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';
import { useAppointmentContext } from 'src/context/AppointmentContext';
import moment from 'moment';
import 'moment/locale/en-gb';

const DateSelector = ({ value, onChange }) => {

  const { startingDate, setStartingDate } = useAppointmentContext();

  const handlePickerChange = async (date) => {
    await new Promise(resolve => setTimeout(resolve, 100)); // Give time for the loading state to render
    setStartingDate(moment(date));
  }

    return <div className='date-picker-wrapper'>
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='en-gb'>
      <DatePicker
        label="Find a Date"
        value={startingDate}
        onChange={handlePickerChange}
        renderInput={(params) => <TextField {...params} />}
        minDate={new Date()}
      />
    </LocalizationProvider>
  </div>
}

export default DateSelector;