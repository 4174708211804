import React from 'react';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { useThemeContext } from 'src/context/ThemeContext';

const Steps = ({step}) => {

  const { primaryColor } = useThemeContext();

  return (
    <div className='appointment-step-container' style={{backgroundColor: primaryColor}}>
      <div className='appointment-step-wrapper'>
        <div className={`appointment-step-container-step ${step === 0 ? "selected" : ""}`}>
          {step === 0 ?
            <CheckCircleOutlineOutlinedIcon className='circle-icon' fontSize='small' />
            : <CircleOutlinedIcon className='circle-icon' fontSize='small' />}
          <div className='text'>Find Appointment</div>
        </div>
        <div className={`appointment-step-container-step ${step === 1 ? "selected" : ""}`}>
          {step === 1 ?
            <CheckCircleOutlineOutlinedIcon className='circle-icon' fontSize='small' />
            : <CircleOutlinedIcon className='circle-icon' fontSize='small' />}
          <div className='text'>Client Details</div>
        </div>
        <div className={`appointment-step-container-step ${step === 2 ? "selected" : ""}`}>
          {step === 2 ?
            <CheckCircleOutlineOutlinedIcon className='circle-icon' fontSize='small' />
            : <CircleOutlinedIcon className='circle-icon' fontSize='small' />}
          <div className='text'>Confirmation</div>
        </div>
      </div>
    </div>
  )
}

export default Steps;
