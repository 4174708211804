import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { Divider} from '@mui/material';
import { Grid } from '@mui/material'; 
import moment from 'moment';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useGlobalContext } from 'src/context/GlobalContext';
import { useThemeContext } from 'src/context/ThemeContext';

const Sidebar = (props) => {

    const {
        branch,
        bookingDetails
    } = useGlobalContext();

    const { secondaryColor } = useThemeContext();

    const dateTime = bookingDetails.dateTime;
    const dateText = dateTime.format('Do MMMM');
    const timeText = dateTime.format('h:mm a') + " - " + moment(dateTime).add(bookingDetails.duration, 'minutes').format('h:mm a');

    const tableRows = [
        {icon: <AssignmentIcon />, text: bookingDetails.appointmentTypeDesc ? bookingDetails.appointmentTypeDesc : 'Type'},
        {icon: <AccessTimeFilledIcon />, text: <span>{dateText}<br></br>{timeText}</span>},
        {icon: <HomeIcon/>, text: branch.name},
        {icon: <LocationOnIcon />, text: branch.address + " " + branch.state + ", " + branch.postcode},
    ]

    return (
        <div className="appointment-wrapper">    
            <div className='title' style={{marginLeft: '20px'}}>Appointment Details</div>
            <Grid container sx={{my: 4}}>
                <Grid item xs={6} className='detail-item'>
                    <img className='optometrist-image' style={{marginBottom: 0}} alt="Practitioner" src={bookingDetails.imgSrc} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/static/images/optometrist/Account.png";
                        }}>
                    </img>
                </Grid>                            
                <Grid item xs={6} className='detail-item'>
                    <div className='detail-item-text' style={{fontWeight: '700'}}> {bookingDetails.doctorName}</div>
                </Grid>
            </Grid>
            {tableRows.map((row, index) => {
                return(
                    <Grid key={index} container sx={{my: 2}}>
                        <Grid item xs={2} className='detail-item'>
                            {row.icon}
                        </Grid>                            
                        <Grid item xs={10} className='detail-item'>
                            <div className='detail-item-text'> {row.text}</div>
                        </Grid>
                    </Grid>
                )
            })}
            <Divider sx={{ mt: 2 }} />
            { branch.bulkbill ? 
            <div>
                <div className='sidebar-detail'>This location provides bulk billed eye tests subject to Medicare eligibility. Please note bulk billing not available on other optometry services including retinal photography and OCT (costs will apply). Please bring your Medicare card and if applicable private health fund card with you to your appointment.</div>
                <Divider sx={{ mt: 2 }} />
            </div>        
            : ""}
            <div className='book-consultation-detail-detail'>
                <div className='title' style={{textAlign: 'center'}}>Need Help? Call</div>
                <div className='call-button' style={{backgroundColor: secondaryColor}}><LocalPhoneIcon sx={{mr: 1}}/>{branch.phone}</div>
            </div>
            <div>
            <Divider sx={{mt: '30px'}}/>
            <div className='powered-Wrapper'>           
                <p>Powered By</p>
                <div>
                    <img className='logo-small' src="static/images/HealthiaNewLogo2018-Horizontal.png" alt="logo-small"/></div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;