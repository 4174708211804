import React from 'react';
import Navigation from 'src/components/Navigation';
import Sidebar from 'src/components/Sidebar';
import { Grid } from '@mui/material';
import { motion } from "framer-motion";
import moment from 'moment';
import { useGlobalContext } from 'src/context/GlobalContext';

const ConfirmationView = ({submitBooking}) => {

  const {
    branch,
    bookingDetails,
    handleWindowChange,
    isMobile
  } = useGlobalContext();

  const handleSubmit = () => {
    submitBooking();
    handleWindowChange(true);
  }

  return (
    <div className='view-wrapper'>
      <Grid container>
        <Grid item sm={8} xs={12} sx={{ borderRight: isMobile ? "none" : "20px solid white", paddingRight: isMobile ? "0" : '40px', paddingBottom: "40px" }}>
          <div className='confirm-detail'>
            <div className='page-title' style={{margin: '30px 0'}}>Confirm Your Details</div> 
            <Grid container rowSpacing={1}>
              {branch.formFields.map((field, index) => {
                return (
                  <Grid item xs={12} lg={field.label === 'Notes' ? 12 : 6} key={index}>
                    <motion.div key={index} animate={{ x: 0 }} initial={{ x: -75 }} transition={{ ease: "easeOut", duration: 0.3, delay: 0.05 * index }}  style={{ padding: '0px 5px' }}>  
                      <div style={{
                          pt: 2, 
                          backgroundColor: 'white', 
                          display: 'flex', 
                          borderRadius: '5px 5px 0 0', 
                          marginTop: '10px', 
                          padding: '20px 20px 0 20px',
                          borderBottom: '1px solid black'
                        }}>  
                        <div className='confirm-detail-item'>{field.label}:</div>
                        <div className='confirm-detail-value'>{field.name === 'DOB' ? moment(bookingDetails['DOB']).format('DD/MM/YYYY') : bookingDetails[field.name]}</div>      
                      </div>
                    </motion.div>
                  </Grid>
                )
              })}
            </Grid>
            <Navigation 
                buttonValue={'Book Now'}
                onBack={() => handleWindowChange(false)}
                onContinue={handleSubmit}
              />
          </div>
        </Grid>
        <Grid item sm={4} xs={12} sx={{ paddingLeft: 3 }}>
          <Sidebar/>
        </Grid>
      </Grid>
    </div>
  );
}

export default ConfirmationView;