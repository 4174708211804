export const getScrollbarWidth = () => {
    // Create a temporary div
    const scrollDiv = document.createElement("div");
    scrollDiv.style.width = "100px";
    scrollDiv.style.height = "100px";
    scrollDiv.style.overflow = "scroll"; // Forces scrollbar to appear
    scrollDiv.style.position = "absolute"; // Avoid affecting layout
    scrollDiv.style.top = "-9999px"; // Hide it off-screen
  
    // Append it to the body and measure the scrollbar width
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  
    // Remove the temporary div
    document.body.removeChild(scrollDiv);
  
    return scrollbarWidth;
};