import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import BookingPortal from 'src/BookingPortal.js';
import AppointmentContextProvider from './context/AppointmentContext';
import GlobalContextProvider from './context/GlobalContext';
import ThemeContextProvider from './context/ThemeContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(    
    <GlobalContextProvider>
      <AppointmentContextProvider>
        <ThemeContextProvider>
          <div className='main-container-wrapper-outer' style={{ height: "100%", width: "100%" }}>
            <BookingPortal />
          </div>
        </ThemeContextProvider>
      </AppointmentContextProvider>
    </GlobalContextProvider>
);
